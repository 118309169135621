// Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, CustomEase,
// DrawSVGPlugin, ScrollSmoother, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper,
// SplitText, CustomBounce, CustomWiggle,
import { gsap, ScrollTrigger, Physics2DPlugin } from "gsap/all";

gsap.registerPlugin(ScrollTrigger, Physics2DPlugin);

let mm = gsap.matchMedia();

export default () => {
  //------------------------------------------------------//
  // Setup 🧦 GSAP and register 📜 ScrollTrigger
  // Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
  // Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
  //------------------------------------------------------//
  // gsap.registerPlugin(Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, CustomEase, DrawSVGPlugin, ScrollSmoother, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper, SplitText, CustomBounce, CustomWiggle);

  ScrollTrigger.defaults({
    toggleActions: "restart pause resume pause",
    markers: window.location.hostname.endsWith(".local") ? true : false,
  });

  // END Setup 🧦 GSAP -------------------------------------//

  //------------------------------------------------------//
  // FAQ toggle
  //------------------------------------------------------//
  document.querySelectorAll(".form-toggle-container").forEach((container) => {
    const items = container.querySelectorAll(".alloy-item");
    items.forEach((item) => {
      // The animation
      const tl = gsap.timeline({
        defaults: { duration: 0.3 },
        reversed: true,
        paused: true,
      });
      tl.set(item.querySelector(".hide"), { autoAlpha: 1 });

      tl.to(item.querySelector(".hide"), { height: "auto" });
      tl.to(item.querySelector(".icon"), { rotate: 180 }, "<");

      // The event listener
      item.addEventListener("click", (e) => {
        tl.reversed() ? tl.play() : tl.reverse();
      });
    });
  });
  // END FAQ toggle -------------------------------------//
  //--------------------------------//
  // ⭐ Kenmerken
  //--------------------------------//
  document
    .querySelectorAll(".ACF-kenmerken .grid")
    .forEach(function (container) {
      const q = gsap.utils.selector(container);

      const targets = q(".item");
      const numberOfTargets = targets.length;
      const duration = 1.5;
      const pause = 4; // change this
      const stagger = duration + pause;
      const repeatDelay = stagger * (numberOfTargets - 1) + pause;

      gsap.set(targets, {
        xPercent: 150,
        scale: 0,
      });

      const tl = gsap.timeline({
        // paused: true,
        defaults: {
          duration: duration,
        },
        scrollTrigger: {
          trigger: container,
          start: "top bottom",
          end: "bottom top",
          toggleActions: "play pause play pause",
        },
      });

      // Animation #1
      tl.to(targets, {
        xPercent: "-=50", // move from it's current postion 1 postion over
        scale: 0.5,
        transformOrigin: "center left", // set the scale  to come from from the correct side
        stagger: {
          each: stagger,
          repeat: -1,
          repeatDelay: repeatDelay,
        },
      });

      // Animation #2
      tl.to(
        targets,
        {
          zIndex: 5,
          scale: 1,
          xPercent: "-=100", // move from it's current postion 1 postion over
          stagger: {
            each: stagger,
            repeat: -1,
            repeatDelay: repeatDelay,
          },
        },
        stagger, // start when the first has animated
      );

      // Animation #3
      tl.to(
        targets,
        {
          scale: 0.5,
          transformOrigin: "center right",

          xPercent: "-=100", // move from it's current postion 1 postion over

          stagger: {
            each: stagger,
            repeat: -1,
            repeatDelay: repeatDelay,
          },
        },
        stagger * 2, // start when the second has animated
      );

      // Animation #4
      tl.to(
        targets,
        {
          zIndex: -1,
          // xPercent: "-=100", // move from it's current postion 1 postion over
          transformOrigin: "center right", // change the scale  to come from from the correct side
          scale: 0,
          stagger: {
            each: stagger,
            repeat: -1,
            repeatDelay: repeatDelay,
          },
        },
        stagger * 3, // start when the third has animated
      );

      tl.time(stagger * 3);
      // Create an helper to easily debug the animation. This premium plugin you can just freely use on Codepen 🎉
      // GSDevTools.create({ animation: tl });
    });
  // END ⭐ Kenmerken -------------------------------------//
  //------------------------------------------------------//
  // Animation random ornaments
  //------------------------------------------------------//
  document.querySelectorAll(".has-ornaments").forEach(function (container) {
    // Create ornaments container
    const ornaments = document.createElement("ul");
    ornaments.classList.add("ornaments");
    container.appendChild(ornaments);

    // Setup
    const amount = gsap.utils.random(2, 3);
    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;
    const items = [];

    for (let i = 0; i < amount; i++) {
      const item = document.createElement("li");
      const random = {
        class: gsap.utils.random(["one", "two", "three"]),
        size: gsap.utils.random([50, 70, 100, 50]),
      };
      ornaments.appendChild(item);
      items.push(item);
      item.classList.add(random.class);
      gsap.set(item, {
        width: random.size,
        height: random.size,
      });
      let fromEdge = gsap.utils.random(-30, -random.size);

      const randomPositionH = Math.floor(Math.random() * containerHeight);
      // Postion elments left or right
      if (i % 2) {
        item.style.top = randomPositionH + "px";
        item.style.left = fromEdge + "px";
      } else {
        item.style.top = randomPositionH + "px";
        item.style.right = fromEdge + "px";
      }
    }

    // GSAP  ScrollTrigger
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: container, // What element triggers the scroll
        scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
        start: "top bottom", // Can be top, center, bottom
      },
    });

    // console.warn(items);

    timeline.to(items, {
      // Get the max scroll height and get a random percentage from that value
      y: (index) =>
        -(container.offsetHeight / 2) *
        gsap.utils.wrap([1.2, 0.8, 0.4, 0.1], index),
      ease: "none",
    });
  });
  // END Animation random ornaments -------------------------------------//

  document.querySelectorAll(".alloy-header-type-fancy").forEach((container) => {
    const q = gsap.utils.selector(container);
    let count = 0;
    let setCount = 0;
    let scale = gsap.utils.wrap([0.55, 0.75]); // "yellow" (index 5 maps to index 2 in a 3-element Array)
    let image = gsap.utils.wrap([0, 1, 2, 3]);
    gsap.set(q(".color"), { scale: 0, transformOrigin: "center center" });
    const tl = gsap.timeline({
      // yoyo: true,
      repeat: -1,
      repeatRefresh: 4,
      onRepeat: () => {
        count++;
      },
      repeatRefresh: true,
      scrollTrigger: {
        trigger: container,
        start: "top bottom",
        end: "bottom top",
        toggleActions: "play pause play pause",
      },
    });
    tl.set(q(".item"), {
      scale: (index) => scale(index + count),
    })
    tl.set(
      q(".color"),
      {
        scale: 1,
        // duration: 2,
        // stagger: 0.2,
        // transformOrigin: "center center",
      },
      "<",
    );
    // tl.to(q(".item"), {
    //   scale: (index) => scale(index + count),
    //   duration: 2,
    //   ease: "back.out(1.7)",
    //   stagger: 0.2,
    //   onStart: function () {
    //     this.timeline.vars.targets.forEach((item, index) => {
    //       const k = gsap.utils.selector(item);
    //       gsap.set(k("img"), {
    //         opacity: (i) => (i === image(index + setCount) ? 1 : 0),
    //       });
    //     });
    //     setCount++;
    //   },
    // });
    // tl.to(
    //   q(".color"),
    //   {
    //     scale: 1,
    //     duration: 2,
    //     stagger: 0.2,
    //     transformOrigin: "center center",
    //   },
    //   "<",
    // );
    // tl.to(q(".item"), {
    //   scale: 0,
    //   duration: 1,
    //   stagger: 0.2,
    // });
    // tl.to(
    //   q(".color"),
    //   {
    //     scale: 0,
    //     duration: 1,
    //     stagger: 0.2,
    //     transformOrigin: "center center",
    //   },
    //   "<",
    // );
  });

  //--------------------------------//
  // 404 
  //--------------------------------//
  document.querySelectorAll(".error404").forEach(container => {
    const angle = 20;
    const bullets = [];
    const bulletsContainer = document.querySelector(".flair-container");
    const amount = 40;
    const duration = 10;

    for (let i = 0; i < amount; i++) {
      const flairBullet = document.createElement("div");
      bulletsContainer.appendChild(flairBullet);
      bullets.push(flairBullet);
      gsap.set(flairBullet, { scale: "random(1, 2)" });
    }

    const tl = gsap.timeline({
      repeat: -1
    })
    tl.to(
      bullets,
      {
        duration: duration,
        physics2D: {
          velocity: "random(600, 850)",
          angle: "random(230, 290)",
          gravity: 250
        },
        stagger: {
          amount: duration
        }
      },
    );


    // Create an helper to easily debug the animation. This premium plugin you can just freely use on Codepen 🎉
  });
  // END 404 --------------//


};
